<template>
  <div class="subpage-nav">
    <div class="constrain h-full">
      <div class="relative h-full flex items-center justify-center">
        <Go :to="link" class="absolute left-1 top-1/2 transform -translate-y-1/2">
          <Arrow class="w-4 transform rotate-90" />
        </Go>
        <h1 class="h2 mb-0" v-text="payload.title" />
      </div>
    </div>
  </div>
</template>

<script>
import Arrow from '@/assets/images/arrow.svg';

export default {
  components: { Arrow },
  props: { payload: { type: Object, default: Object } },
  computed: {
    link() {
      const { locale } = this.$store.state.router;
      if (this.$store.state.router.previousPage && this.$store.state.router.previousPage.meta
        && this.$store.state.router.previousPage.meta.translatedPaths[locale]) {
        return this.$store.state.router.previousPage.meta.translatedPaths[locale];
      }

      return this.payload.parentLink;
    },
  },
};
</script>

<style lang="scss" scoped>
.subpage-nav {
  @apply relative bg-yellow-light;

  position: fixed;
  top: var(--header-height);
  height: var(--header-height);
  width: 100%;
  z-index: 10;
}

.h2 {
  font-size: 1.1rem; // make text a little bit smaller on mobile

  @screen sm {
    font-size: 1.2rem; // this is de default h2 size
  }
}
</style>
